<template>
  <KTCard :page="page">
    <template #toolbar>
      <KTAddButton :to="addRoute" v-if="!finalizado" />
    </template>
    <template #body>
      <KTTable
        :fields="despesa_operacional_columns"
        :items="despesa_operacional.data"
        :primaryKey="despesa_operacional.primaryKey"
        :sortBy="despesa_operacional.sortBy"
      >
        <template #cell(_actions)="{ item }">
          <template v-if="item.origem == 1">
            <KTViewMiniButton
              v-if="finalizado"
              :to="getEditRoute(item.idpip_despesa_operacional)"
            />
            <template v-else>
              <KTEditMiniButton
                class="mr-3"
                :to="getEditRoute(item.idpip_despesa_operacional)"
              />
              <KTRemoveMiniButton
                @click="ApiRemoveItem(item.idpip_despesa_operacional)"
              />
            </template>
          </template>
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipDespesaOperacionalList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-thumbs-down",
        title: "Despesas Operacionais",
        description: `Despesas operacionais são as despesas necessárias à
          atividade da empresa e à manutenção da respectiva fonte produtora.
          São considerados custos e despesas operacionais todos os gastos
          realizados com o objetivo final de gerar receitas e diretamente
          relacionados com a natureza específica dos negócios de uma empresa,
          seja ela rural ou urbana. Clique no botão Adicionar para cadastrar
          uma despesa. Para remover ou editar algum item use os respectivos
          botões localizados abaixo de AÇÕES.`
      },
      despesa_operacional: {
        data: [],
        primaryKey: "idpip_despesa_operacional",
        sortBy: "descricao"
      },
      anos: 0,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/despesa_operacional`;
    },
    manual() {
      return {
        session: "despesa_operacional",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    addRoute() {
      return {
        name: "pip_despesa_operacional_new",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    despesa_operacional_columns() {
      let columns = [
        {
          key: "_actions",
          label: "Ações",
          sortable: false,
          tdClass: "text-center"
        },
        {
          key: "descricao_despesa_operacional",
          label: "Discriminação",
          sortable: true
        },
        {
          key: "descricao_tipo_despesa_operacional",
          label: "Tipo",
          sortable: true
        },
        {
          key: "simbolo_moeda",
          label: "Moeda",
          sortable: true,
          tdClass: "text-center"
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "valor_ano_" + ano,
          label: "Valor Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "currency"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.despesa_operacional.data = res[R_GETLIST].despesa_operacional;
        this.anos = res[R_GETLIST].anos;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    getEditRoute(id) {
      return {
        name: "pip_despesa_operacional_edit",
        params: {
          idplano_investimento: this.idplano_investimento,
          idpip_despesa_operacional: id
        }
      };
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
